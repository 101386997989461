import getBrandName from "./getBrandName";

const getBrandId = () => {
  let LocalStotageSuperBrandId = localStorage.getItem("superBrandId");
  if (!!LocalStotageSuperBrandId) {
    return LocalStotageSuperBrandId;
  }
  let brandName = getBrandName();
  const { REACT_APP_BRAND_ID } = process.env;

  let superBrandId;
  superBrandId =
    process.env?.[`REACT_APP_BRAND_ID_${brandName.toUpperCase()}`] ||
    REACT_APP_BRAND_ID;
  if (process.env.NODE_ENV === "development") {
    superBrandId = 3;
  }
  localStorage.setItem("superBrandId", superBrandId);
  return superBrandId;
};

export default getBrandId;
